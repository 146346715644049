import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

export const initFeaturesSlider = () => {
    // VARS
    const slider = document.querySelector('[data-features-slider]');
    if (!slider) return;
    const iconButtons = slider.querySelectorAll('[data-features-slider-navigation]');
    const paginationBulletLoader = `<svg class="pagination-loader" data-pagination-bullet-loader xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
          <circle class="pagination-loader__circle" cx="7.5" cy="7.5" r="7" stroke="#383559" />
      </svg>`;

    const sliderOptions = {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 1,
        speed: 400,
        spaceBetween: 100,
        loop: true,
        autoplay: {
            delay: 7000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '[data-features-slider-pagination]',
            clickable: true,
        },
        navigation: {
            nextEl: '[data-features-slider-button-next]',
            prevEl: '[data-features-slider-button-prev]',
        },
        on: {
            afterInit: function () {
                addPaginationBulletLoader();
            },
            slideChange: function (sliderObj) {
                deletePaginationBulletsLoaders();
                addPaginationBulletLoader();
                toggleIconNavigation(sliderObj.realIndex);
            },
        },
    };

    let featuresSlider;

    // INITS
    toggleSlider();

    // LISTENERS
    window.addEventListener('resize', handleOnResizeWindow, false);

    [...iconButtons].forEach((button) => {
        button.addEventListener('click', handleOnClickIconButton, false);
    });

    // HANDLERS
    function handleOnResizeWindow() {
        toggleSlider();
    }

    function handleOnClickIconButton() {
        const index = this.dataset.featuresSliderNavigation;
        featuresSlider.slideTo(index);
    }

    // FUNCTIONS
    function toggleSlider() {
        if (window.innerWidth < 600) {
            if (featuresSlider) {
                featuresSlider.destroy();
            }
        } else {
            featuresSlider = new Swiper(slider, sliderOptions);
        }
    }

    function addPaginationBulletLoader() {
        const activePaginationBullet = slider.querySelector('.swiper-pagination-bullet-active');

        if (activePaginationBullet) {
            activePaginationBullet.insertAdjacentHTML('beforeend', paginationBulletLoader);
        }
    }

    function deletePaginationBulletsLoaders() {
        const loaders = slider.querySelectorAll('[data-pagination-bullet-loader]');

        [...loaders].forEach((loader) => {
            loader.remove();
        });
    }

    function toggleIconNavigation(index) {
        const activeClass = 'features-slider__navigation-button--active';

        [...iconButtons].forEach((button) => {
            button.classList.remove(activeClass);
        });

        iconButtons[index].classList.add(activeClass);
    }
};
